"use client";

import type { Config, Mixpanel } from "mixpanel-browser";
import mixpanel from "mixpanel-browser";
import type { ReactNode } from "react";
import { createContext } from "react";

export interface WithMixPanel {
  mixpanel: Mixpanel;
}

export const MixPanelContext = createContext({} as WithMixPanel);

export const MixPanelProvider = ({
  children,
  token = "",
  config = {},
}: {
  children: ReactNode;
  token?: string;
  config?: Partial<Config>;
}) => {
  if (token) {
    mixpanel.init(token, config);
  }

  const exposed: WithMixPanel = {
    mixpanel,
  };

  return (
    <MixPanelContext.Provider value={exposed}>
      {children}
    </MixPanelContext.Provider>
  );
};
