"use client";

import type { ReactNode } from "react";
import { createContext, useState } from "react";

export interface WithMenu {
  isOpen: boolean;
  toggleMenu: () => void;
  setMenu: (val: boolean) => void;
}

export const MenuContext = createContext({
  isOpen: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleMenu: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMenu: () => {},
} as WithMenu);

export const MenuProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleMenu = () => {
    setIsOpen((open) => !open);
  };

  const setMenu = (val: boolean) => {
    setIsOpen(val);
  };

  const exposed: WithMenu = {
    isOpen,
    toggleMenu,
    setMenu,
  };

  return (
    <MenuContext.Provider value={exposed}>{children}</MenuContext.Provider>
  );
};
