"use client";

import { supabase } from "@/lib/supabase/client";
import type { AuthError, User } from "@supabase/supabase-js";
import type React from "react";
import { createContext, useEffect, useState } from "react";

import { env } from "~/env.mjs";

export interface CreateContextInterface {
  user: User | null;
  auth: boolean;
  loading: boolean;
  error: AuthError | null;
  refreshToken: string | null;
  accessToken: string | null;
  clearError: () => void;
  signOut: () => Promise<void>;
  signInWithOAuth: () => Promise<void>;
  refreshAuthToken: () => Promise<void>;
}

interface SupabaseAuthProviderProps {
  children: React.ReactNode;
}

export const SupabaseAuthContext = createContext<CreateContextInterface>({
  user: null,
  error: null,
  auth: false,
  loading: false,
  refreshToken: null,
  accessToken: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearError: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signInWithOAuth: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signOut: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshAuthToken: async () => {},
});

export function SupabaseAuthProvider({
  children,
}: SupabaseAuthProviderProps): React.JSX.Element {
  const expiryMargin = 1000;
  const [user, setUser] = useState<User | null>(null);
  const [auth, setAuth] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [expiresAt, setExpiresAt] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AuthError | null>(null);

  const signInWithOAuth = async (): Promise<void> => {
    setLoading(true);
    setError(null);
    const { error: e } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: { redirectTo: env.NEXT_PUBLIC_AUTH_CALLBACK },
    });
    setLoading(false);
    if (e) {
      setError(e);
      return Promise.reject(e);
    }
  };

  // Sign Out
  const signOut = async (): Promise<void> => {
    setLoading(true);
    setError(null);
    const { error: e } = await supabase.auth.signOut();
    setLoading(false);
    if (e) {
      setError(e);
    }
  };

  const refreshAuthToken = async (): Promise<void> => {
    if (!refreshToken || !expiresAt) return;
    if (expiresAt < Math.floor(Date.now() / 1000) + expiryMargin) {
      const {
        data: { session },
      } = await supabase.auth.refreshSession({
        refresh_token: refreshToken,
      });
      setRefreshToken(session?.refresh_token ?? null);
      setAccessToken(session?.access_token ?? null);
      setExpiresAt(session?.expires_at ?? null);
    }
  };

  const clearError = (): void => {
    setError(null);
  };

  // Refresh the Page to Sync Server and Client
  useEffect(() => {
    setLoading(true);
    const getUser = async (): Promise<void> => {
      const { data: useData } = await supabase.auth.getUser();
      const { data: sessionData } = await supabase.auth.getSession();
      const { user: currentUser } = useData;
      const { session } = sessionData;
      setUser(currentUser ?? null);
      setAuth(Boolean(currentUser));
      setRefreshToken(session?.refresh_token ?? null);
      setAccessToken(session?.access_token ?? null);
      setExpiresAt(session?.expires_at ?? null);
      setLoading(false);
    };
    void getUser();
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" && session) {
        setUser(session.user);
        setAuth(true);
        setError(null);
        setRefreshToken(session.refresh_token);
        setExpiresAt(session.expires_at ?? null);
        setAccessToken(session.access_token);
      }
      if (event === "SIGNED_OUT") {
        setUser(null);
        setAuth(false);
        setAccessToken(null);
        setRefreshToken(null);
        setExpiresAt(null);
        setError(null);
      }
      setLoading(false);
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  const exposed: CreateContextInterface = {
    user,
    auth,
    accessToken,
    refreshToken,
    loading,
    error,
    clearError,
    signInWithOAuth,
    signOut,
    refreshAuthToken,
  };

  return (
    <SupabaseAuthContext.Provider value={exposed}>
      {children}
    </SupabaseAuthContext.Provider>
  );
}
