"use client";

import { MenuProvider } from "@/context/menu";
import { MixPanelProvider } from "@/context/mixpanel";
import { SupabaseAuthProvider } from "@/context/supabase";
import { env } from "@env";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { OnbordaProvider } from "onborda";
import { ReactFlowProvider } from "reactflow";

import {
  QueryClientProvider,
  ReactQueryStreamedHydration,
  queryClient,
} from "@flow/api";
import { Toaster as SonnerToaster } from "@flow/ui/sonner";
import { Toaster } from "@flow/ui/toaster";
import { TooltipProvider } from "@flow/ui/tooltip";

export function Providers({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryStreamedHydration>
        <ReactFlowProvider>
          <NextThemesProvider attribute="class" defaultTheme="light">
            <SupabaseAuthProvider>
              <TooltipProvider>
                <MenuProvider>
                  <OnbordaProvider>
                    <MixPanelProvider
                      token={env.NEXT_PUBLIC_MIXPANEL_TOKEN}
                      config={{
                        persistence: "cookie",
                        ignore_dnt: true,
                      }}
                    >
                      {children}
                      <Toaster />
                      <SonnerToaster />
                    </MixPanelProvider>
                  </OnbordaProvider>
                </MenuProvider>
              </TooltipProvider>
            </SupabaseAuthProvider>
          </NextThemesProvider>
        </ReactFlowProvider>
      </ReactQueryStreamedHydration>
    </QueryClientProvider>
  );
}
