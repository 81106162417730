import(/* webpackMode: "eager", webpackExports: ["HypertuneProvider"] */ "/vercel/path0/apps/client/generated/hypertune.react.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/client/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toolbar"] */ "/vercel/path0/apps/client/src/components/toolbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/client/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18_vs5sejpsgh7w4tmvhuinin2aua/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/reactflow@11.11.4_@types+react@18.3.9_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/reactflow/dist/style.css");
