"use client";

import { VercelToolbar } from "@vercel/toolbar/next";

import { useAuth } from "@/hooks/auth";
import { env } from "~/env.mjs";

const isDev = env.NEXT_PUBLIC_NODE_ENV === "development";
const employees = ["philip.knape@gmail.com", "flowanalyticsapp@gmail.com"];

export function Toolbar() {
  const { user } = useAuth();
  const isEmployee = employees.find((employee) => employee === user?.email);
  return isEmployee || isDev ? (
    <VercelToolbar
      onLoad={(toolbar) => {
        console.log(toolbar);
      }}
      onError={(error) => {
        console.log(error);
      }}
    />
  ) : null;
}
